import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { extractNodesEdges } from "../chatbottool/publishTemplate";
import PostConnectDialog from "../../Components/ConnectPost/ConnectToPostDialog";
import SpecificPostInfo from "../../Components/specificpostview/ViewSpecificPostDialog";
import ToastManager from "../../Components/ToastManager";
import { Button } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SetIGLiveDialog from "./SetIGLiveDialog";
interface Data {
  templateName: string;
  connectToPost: string;
  templateType: string;
  liveTemplateStatus: string;
  liveTemplateName: string;
  templateId: any;
  pauseType: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "liveTemplateName",
    numeric: false,
    disablePadding: true,
    label: "Template Name",
  },
  {
    id: "connectToPost",
    numeric: false,
    disablePadding: false,
    label: "Connect To Post",
  },
  {
    id: "liveTemplateStatus",
    numeric: false,
    disablePadding: false,
    label: "Template Status",
  },
  {
    id: "pauseType",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;
  const { title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        minHeight: "53px !important",
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function LiveInstaTempTableData({
  rows = [],
  title,
  setILiveTemplateList,
}: any) {
  //users: Array
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("liveTemplateName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [postDialog, setPostDialog] = React.useState(false);
  const [specificDialog, setSpecificDialog] = React.useState(false);
  const [setLiveDialog, setSetLiveDialog] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [templateInfo, setTemplateInfo] = React.useState({});
  const {
    saveNewNodeAndEdges,
    saveTemplates,
    saveTemplateName,
    setSelectedTemplateIDs,
    setLiveTemp,
  } = useProductsContext();

  const navigate = useNavigate();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    if (rows && rows.length > 0) {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    return [];
  }, [order, orderBy, page, rowsPerPage, rows]);
  let pageId: string = "";
  let instaId: any = null;
  const getAllInstaLiveTemplates = () => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
    pageId = recFbPageInfp?.pageId;
    instaId = recFbPageInfp?.instaId;
    let params: any = "";
    if (pageId) {
      params = `instaId=${instaId}`;
      try {
        NetworkManager.sendJsonRequest(
          "instagram/live/template/getLiveTemplatesByInstaId",
          "",
          params,
          "GET",
          true,
          onSuccessCallInstaLive,
          onErrorCallLive
        );
      } catch (error) {}
    }
  };
  const onSuccessCallInstaLive = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData) {
      const iLiveTemplateDetails = respData.liveTemplateDetailsList;
      setILiveTemplateList(iLiveTemplateDetails);
    } else if (respData.statusCode === "Insta1019") {
      ToastManager.showToast("No Live templates for the instaId", "Error");
    }
  };
  const onErrorCallLive = (err: any) => {};

  const onTemplateNameClick = (e: any, templateRow: any) => {
    localStorage.setItem("selectedConnection", "nav-inst-tab");
    const sendTemplate: any = {
      templateName: templateRow.liveTemplateName,
      templateId: templateRow.liveTemplateId,
      templateType: templateRow.liveTemplateStatus,
    };
    saveTemplateName(sendTemplate);
    let params: any = "";
    let liveTemplateId: any = templateRow.liveTemplateId;
    try {
      params = `liveTemplateId=${liveTemplateId}`;
      NetworkManager.sendJsonRequest(
        "instagram/live/template/getLiveTemplateData",
        "",
        params,
        "GET",
        true,
        onSuccessCall,
        onErrorCall
      );
    } catch (error) {}
  };

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      setSelectedTemplateIDs({
        pageId: respData.pageId,
        instaId: respData.instaId,
      });
      saveTemplates(respData.liveTemplateData);
      saveNewNodeAndEdges([], []);
      const result = extractNodesEdges(respData.liveTemplateData);
      const { nodes, edges } = result;

      saveNewNodeAndEdges(nodes, edges);
      setLiveTemp("livetemplates");
      navigate(`/dashboard/chatbot`, { state: { create: false } });
    }
  };
  const onErrorCall = (err: any) => {
    console.log(err);
  };

  const onConnToPostClick = (e: any, templateRow: any) => {
    if (templateRow.liveTemplateStatus === "active") {
      // do nothing
    } else {
      onTemplateNameSave(templateRow);
      setPostDialog(true);
    }
  };
  const onClickSpecificTemplate = (e: any, templateRow: any) => {
    if (templateRow.liveTemplateStatus === "active") {
      // do nothing
    } else {
      onTemplateNameSave(templateRow);
      setSpecificDialog(true);
    }
  };
  const onTemplateNameSave = async (template: any) => {
    setTemplateInfo({
      templateName: template.liveTemplateName,
      templateId: template.liveTemplateId,
      templateType: template.liveTemplateStatus,
    });
  };
  const onCancelDialogue = (data: any) => {
    if (data === "specific") setSpecificDialog(false);
    else setPostDialog(false);
    setSetLiveDialog(false);
  };

  const onClickSetlive = (e: any, templateRow: any) => {
    onTemplateNameSave(templateRow);
    setSetLiveDialog(true);
  };
  const onClickStoplive = (e: any, templateRow: any) => {
    onTemplateNameSave(templateRow);
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;

    const params = `liveTemplateId=${templateRow.liveTemplateId}&instaId=${
      recFbPageInfp.instaId
    }&setTemplateStatus=${
      templateRow.liveTemplateStatus === "active" ? "inactive" : ""
    }`;

    try {
      NetworkManager.sendJsonRequest(
        "instagram/live/template/setLiveTemplateStatus",
        "",
        params,
        "POST",
        true,
        onSuccessCallLive,
        onErrorCall
      );
    } catch (err: any) {}
  };
  const onSuccessCallLive = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData.statusCode === "Insta0000") {
      ToastManager.showToast(
        "Template is Successfully Set to Non-Live",
        "Success"
      );
      getAllInstaLiveTemplates();
    } else if (respData.statusCode === "Insta1027") {
      ToastManager.showToast(
        "Error Occured while setting the template to Non-live ",
        "Error"
      );
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} title={title} />
          <hr style={{ margin: 0 }} />
          <TableContainer sx={{ height: 325 }} className="custom-scrollbar-css">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.liveTemplateName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.liveTemplateName}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row.liveTemplateName)
                          }
                          color="primary"
                          role="checkbox"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        onClick={(e) => onTemplateNameClick(e, row)}
                      >
                        {row.liveTemplateName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          cursor: `${
                            row.liveTemplateStatus === "active"
                              ? "not-allowed"
                              : "pointer"
                          }`,
                          textTransform: "capitalize",
                        }}
                        onClick={(e) => onConnToPostClick(e, row)}
                      >
                        {row.liveTemplateName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          cursor: `${
                            row.liveTemplateStatus === "active"
                              ? "not-allowed"
                              : "pointer"
                          }`,
                        }}
                        onClick={(e) => onClickSpecificTemplate(e, row)}
                      >
                        {row.liveTemplateStatus === "active"
                          ? "Live Template"
                          : "Non-Live Template"}
                      </TableCell>
                      <TableCell>
                        {row.liveTemplateStatus === "inactive" ? (
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              backgroundColor: "#4aa5dc",
                              padding: "0px 10px !important",
                              "&:hover": {
                                backgroundColor: "#4aa5dc",
                              },
                            }}
                            onClick={(e) => onClickSetlive(e, row)}
                          >
                            {/* <TaskAltIcon fontSize="small" /> */}
                            Set Live
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                backgroundColor: "#fd6c33",
                                padding: "0px 5px !important",
                                "&:hover": {
                                  backgroundColor: "#fd6c33",
                                },
                              }}
                              onClick={(e) => onClickStoplive(e, row)}
                            >
                              {/* <TaskAltIcon fontSize="small" /> */}
                              Stop Live
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <PostConnectDialog
        isOpen={postDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
      />
      <SpecificPostInfo
        isOpen={specificDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
      />
      <SetIGLiveDialog
        setLiveOpen={setLiveDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
        setSetLiveDialog={(flag: boolean) => {
          setSetLiveDialog(false);
          getAllInstaLiveTemplates();
        }}
      />
    </>
  );
}
