import React, { Component } from "react";
import Loading from "react-loading";
import { useProductsContext } from "../Contexts/SidebarContext";
var self;

export default class BusyLoader extends Component {
  componentWillMount() {
    this.setState({
      busyIcon: false,
      color: "#4aa5dc",
      width: "50px",
      height: "50px",
    });
    self = this;
  }

  static showLoader() {
    try {
      self.setState({
        busyIcon: true,
      });
    } catch (e) {}
  }

  static hideLoader() {
    self.setState({
      busyIcon: false,
    });
  }

  render() {
    var absStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-50px",
      // marginLeft: "-100px",
      zIndex: 2017,
    };
    return (
      <div
        className="overlay"
        style={{
          background: "rgb(226 233 233 / 40%)",
          justifyContent: "center",
          position: "fixed",
          width: "100%",
          // height: (document.body.scrollHeight - 10),
          height: window.innerHeight - 10,
          zIndex: 9999,
          display: this.state.busyIcon ? "flex" : "none",
        }}
      >
        <div style={absStyle}>
          <Loading
            type="spin"
            color={this.state.color}
            width={this.state.width}
            height={this.state.height}
          />
        </div>
      </div>
    );
  }
}
